import React from "react"
import Styled from "styled-components"
const Logo = () => {
  return (
    <div>
      <Styledh1>[CLAUDIO]</Styledh1>
    </div>
  )
}

const Styledh1 = Styled.h1`
 letter-spacing: 5px;

`

export default Logo
